@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('../assets/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf');
}

@font-face {
    font-family: 'Open Sans Italic';
    src: url('../assets/fonts/Open_Sans/static/OpenSans/OpenSans-Italic.ttf');
}

* {
    font-family: 'Open Sans', sans-serif;
}

main {
    min-height: calc(100% - 225px);
    padding-top: 10px;
    padding-bottom: 46px;
}

p, h1, h2, h3, h4, h5, h6, label, li {
    color: var(--tm-color-primary);
}

p {
    margin-bottom: 1rem;
}

a {
    color: var(--tm-color-primary);
    text-decoration: underline;
}

a:hover {
    cursor: pointer;
    color: var(--tm-color-secondary);
}

a.btn {
    text-decoration: none;
}

button:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

section:not(:last-child) {
    margin-bottom: 20px;
}

section {
    padding: 3rem 0;   
}

section:nth-of-type(1) {
    padding-top: 0;
}

section:last-child {
    padding-bottom: 0;
}

.text-secondary {
    color: var(--tm-color-secondary) !important;
}

.text-grey {
    color: var(--tm-color-grey-2);
}

.text-success {
    color: var(--tm-color-success) !important;
}

.text-warning {
    color: var(--tm-color-warning) !important;
}

.text-danger {
    color: var(--tm-color-danger) !important;
}

.bg-success {
    background: var(--tm-color-success) !important;
}

.bg-warning {
    background: var(--tm-color-warning) !important;
}

.bg-danger {
    background-color: var(--tm-color-danger) !important;
}

.bg-primary-light-tint {
    background-color: var(--tm-color-primary-light-tint);
}

.btn {
    border: none;
    padding: .4rem 1.5rem;
}

.btn-link {
    color: var(--tm-color-secondary);
}

.btn-link:hover {
    color: var(--tm-color-secondary);
}

.btn-primary {
    background-color: var(--tm-color-secondary) !important;
}

.btn-secondary {
    background-color: var(--tm-color-tertiary) !important;
    color: var(--tm-color-primary) !important;
}

.btn-dark {
    background: var(--tm-color-grey-2) !important;
}

.btn-light {
    background: var(--tm-color-grey) !important;
    color: var(--tm-color-grey-2) !important;
}

.btn-edit {
    background: none !important;
    color: var(--tm-color-secondary);
    text-decoration: underline;
    padding: 0;
}

li .btn {
    text-align: left;
}

.rounded-circle {
    display: inline-block;
}

.image-container {
    overflow: hidden;
}

.image-container img {
    max-width: 100%;
}

.title {
    font-family: 'Open Sans Bold', sans-serif;
}

.bold {
    font-family: 'Open Sans Bold', sans-serif;
}

.section-title {
    margin-bottom: 1.5rem;
}

.title-big-md {
    margin-bottom: 1.5rem;
}

.title-big-md.has-icon {
    margin-bottom: 0;
}

.title-big-md img {
    width: 35px;
    margin-right: 1rem;
}

.description-title {
    margin-bottom: .25rem;
}

.form-group p {
    margin-bottom: .5rem;
}

.form-group, .input-group {
    margin-bottom: 1.5rem;
}

.input-error {
    display: none;
}

.input-error.active {
    display: block;
}

.error-list li {
    color: #dc3545;
}

hr {
    border-width: 1px;
}

.icon {
    width: 1rem;
    margin-right: .5rem;
}

.title .icon {
    width: 2rem;
}

.visibility-block {
    background: var(--tm-color-grey);
    border: 1px solid var(--tm-color-grey-3);
    border-radius: .2rem;
    cursor: pointer;
    padding: 0 .8rem;
}

.visibility-block.active {
    background: #9fc551;
    color: #FFF;
    border: 1px solid #9fc551;
}

.visibility-block span {
    font-size: .8rem;
}

.row.gx-1 > div:first-child {
    padding-right: .5rem;
}

.row.gx-1 > div:last-child {
    padding-left: .5rem;
}

.indicator {
    display: flex;
    justify-content: space-evenly;
    color: var(--tm-color-grey-2);
    font-size: .8rem;
    padding: 0 1.5rem;
    margin-bottom: 1rem;
}

.extra-options {
    display: none;
}

.extra-options.active {
    display: block;
}

.media-content {
    height: 150px;
    background: var(--tm-color-grey);
    margin-bottom: .5rem;
}

.media-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
}

.media-buttons > div {
    width: 22%;
}

.media-upload-btn {
    width: 100%;
    background: var(--tm-color-grey);
    color: var(--tm-color-grey-2);
    text-align: center;
    padding: .5rem .2rem;
}

.media-upload-btn .icon {
    width: 1.5rem;
    margin-right: 0;
    margin-bottom: .2rem;
}

.steps-navigation {
    display: flex;
    justify-content: space-between;
}

.theme {
    background: var(--tm-color-grey);
    padding: .5rem .25rem;
    margin-bottom: 1rem;
}

.theme > .row > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.theme > .row > div:first-child {
    align-items: center;
}

.theme > .row > div:last-child {
    align-items: flex-start;
}

.theme .rounded-circle {
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--tm-color-secondary);
    color: var(--tm-color-secondary);
    font-size: 1rem;
}

.theme .rounded-circle.bg-danger, .theme .rounded-circle.bg-warning, .theme .rounded-circle.bg-success {
    border: none;
}

.theme p {
    margin-bottom: 0;
}

.priorities-list {
    list-style-type: none;
}

.priority-circle {
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;
    margin-right: .5rem;
}

.big-circle {
    width: 250px;
    height: 250px;
    display: block;
    position: relative;
    border-radius: 50%;
    margin: 100px auto 100px auto;
}

.big-circle > span {
    width: 60px;
    height: 60px;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    text-align: center;
}

.big-circle > span > div {
    font-size: 12px;
    color: var(--tm-color-white);
}

.one {
    top: -60px;
    left: 95px;
}

.two {
    top: -22px;
    left: 195px;
}

.three {
    top: 60px;
    left: 245px;
}

.four {
    top: 150px;
    left: 240px;
}

.five {
    top: 220px;
    left: 185px;
}

.six {
    top: 250px;
    left: 95px;
}

.seven {
    top: 220px;
    left: 5px;
}

.eight {
    top: 150px;
    left: -50px;
}

.nine {
    top: 60px;
    left: -55px;
}

.ten {
    top: -22px;
    left: -5px;
}

.person-container {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    text-align: center;
}

.person-container img {
    width: 65%;
}

.person-container p {
    position: absolute;
    top: 75%;
    color: #000;
    font-size: 14px;
    margin-bottom: 0;
}

.person-container .input {
    left: 20%;
}

.person-container .general {
    top: 85%;
    left: 37%;
}

.person-container .output {
    left: 65%;
}

.person-container .rounded-circle {
    width: 35px;
    height: 35px;
    background: #FFF;
    line-height: 1rem;
    text-align: center;
}

.theme-number-circle {
    width: 15px;
    height: 15px;
    background: var(--white);
    text-align: center;
}

.theme-output-number {
    margin-top: -5px;
}

.theme-output-text {
    margin-top: -5px;
}

.dropdown {
    display: none;
    transition: .3s all ease-in-out;
}

.dropdown.active {
    display: block;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background: rgba(0, 0, 0, .5);
    z-index: 60;
}

.table th, .table td {
    vertical-align: middle;
}

.admin-table th:last-child,  .admin-table td:last-child {
    text-align: right;
}


.attachment-modal {
    width: 90%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    background: #FFF;
    padding: 1rem;
}

.video-container video {
    max-width: 100%;
}

.audio-container div {
    max-width: 100%;
}

.image-container img {
    max-width: 100%;
}

/* Styles of ion-elements generated in TS files, need to be on the most outer css file, to take effect. */
.priorities-action-sheet #high-prio {
    --button-color: var(--tm-color-danger);
    --color: var(--tm-color-danger);
}

.priorities-action-sheet #medium-prio {
    --button-color: var(--tm-color-warning);
    --color: var(--tm-color-warning);
}

.priorities-action-sheet #low-prio {
    --button-color: var(--tm-color-success);
    --color: var(--tm-color-success);
}

/* Ion elements styling */
ion-label {
    color: var(--tm-color-primary);
}

ion-input, ion-textarea, ion-select {
    border: 1px solid var(--tm-color-grey-3);
    border-radius: .25rem;
    text-align: left;
    --padding-start: .5rem !important;
    --padding-end: .5rem !important;
}

ion-popover {
    --max-width: 300px;
    --width: 100%;
}

ion-range {
    --bar-height: 8px;
    --bar-background: var(--tm-color-grey);
    --bar-background-active: var(--tm-color-tertiary);
    --knob-size: 40px;
    --knob-box-shadow: 0px 2px 4px rgba(140,140,140,0.4), 0px -2px 4px rgba(140,140,140,0.4);
    padding: .5rem 0;
    --pin-background: transparent;
    --pin-color: #000000;
}

ion-range button {
    background: none;
}

ion-range .icon {
    width: 1.5rem;
}
ion-range::part(pin) {
    transform: none;
    position: relative;
    top: 24px;
    z-index: 10;
    background:transparent;
}

ion-range::part(tick) {
    background:#EEEEEE ! important;
}

.custom-toast {
    --background: #81C341;
    --color:#FFFFFF;
    --max-width:400px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
}

ion-toast {
    height:100px;
    top:auto;
    bottom:30px;
}

ion-toast.custom-toast {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    --box-shadow: none;
    --background:transparent;
    &::part(container) {
        color: #fff;
        background: #81C341;
        --background: #81C341;
        border-radius: 10px;
        margin-bottom: 30px;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.toast-button-cancel {
    color:#FFFFFF ! important;
}
ion-toast::part(button) {
    color:#FFFFFF ! important;
}
ion-toast::part(div) {
    border:1px solid red;
}

textarea {
    color: rgb(167, 169, 172);
}

.btn.text-grey,
.btn.text-grey:hover {
   color: rgb(167, 169, 172);
}

.big-circle {
    position:relative;
}

.big-circle.round::before {
    display:block;
    content:' ';
    position:absolute;
    top:-85px;
    left:-85px;
    width:calc(100% + 170px);
    height:calc(100% + 170px);
    border:1px solid var(--tm-color-primary);
    border-radius:50%;
}

.steps-navigation .btn.disabled {
    opacity:0 ! important;
}

/* Tablet */
@media only screen and (min-width: 768px){
    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .max-width-md-720-px {
        max-width: 720px;
        margin: 0 auto;
    }

    .btn-normal-md {
        width: auto;
        display: initial;
        padding: .5rem 2rem;
    }

    .title-big-md {
        font-size: 1.4rem;
    }

    .title-big-md img {
        width: 50px;
    }

    .steps-navigation {
        justify-content: center;
    }

    .steps-navigation button, .steps-navigation a {
        width: auto;
        margin-right: 1rem;
    }

    .indicator {
        padding: 0;
    }

    .attachment-modal {
        width: 600px;
    }
}

/* Tablet */
@media only screen and (min-width: 1200px) {
    .max-width-xl-1140-px {
        max-width: 1140px;
        margin: 0 auto;
    }

    .extra-options {
        display: block;
    }
}

.indicator {
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -10px;
}

.indicator span {
    display:inline-block;
    width:50px;
    text-align: center;;
}